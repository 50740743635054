:root {
    --primary-level-1: #23366e;
    --primary-level-2: #118aca;
    --primary-level-3: #31b1ff;
    --basic-level-1: #4a4a4a;
    --basic-level-2: #6f6f6f;
    --basic-level-3: #94999c;
    --basic-level-4: #dbdbdb;
    --basic-level-5: #d8dde6;
    --basic-level-6: #f4f8fb;
    --basic-level-7: #ffffff;
    --feedbacks-level-1: #00d896;
    --feedbacks-level-2: #d0021b;
    --feedbacks-level-3: #fcb20a;
    --secondary-level-1: #abe1fa;
    --secondary-level-2: #03a9f4;
    --secondary-level-3: #409db6;
    --secondary-level-4: #303f9f;
    --secondary-level-5: #bf3fff;
    --secondary-level-6: #a457c4;
    --secondary-level-8: #7620bb;
    --secondary-level-9: #32b88b;
    --secondary-level-10: #2ab92e;
    --secondary-level-11: #008738;
    --secondary-level-12: #035a27;
    --secondary-level-13: #e2af9a;
    --secondary-level-14: #a54e48;
    --secondary-level-15: #83402b;
    --secondary-level-16: #58200f;
  }
