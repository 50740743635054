@import '../../Colors/colors.css';

.Nav {
  background: var(--primary-level-1);
  background: linear-gradient(
    180deg,
    var(--primary-level-1) 0%,
    var(--primary-level-2) 100%
  );
  display: flex;
  padding: 10px;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  position: relative;
}

.NavLogo {
  display: block;
  padding: 10px;
}

.NavLogo img {
  display: block;
  margin: 0 auto;
}

.Nav .NavButton:focus,
.Nav .NavButton:active,
.Nav .active .NavButton {
  width: 100%;
  background-image: linear-gradient(to right, #23366e00, rgba(255, 255, 255, 0.3));
  border-right: 4px solid rgba(255, 255, 255, 0.4);
  padding-right: 6px;
}

.Nav a {
  text-decoration: none;
  color: #fff;
  display: block;
}

.Nav .NavContent {
  display: block;
  position: absolute;
  left: 0;
  top: 61px;
  width: 100%;
  height: calc(100vh - 61px);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.Nav .NavContent.open {
  transform: translateX(0);
}

.Nav .NavContent .NavButton {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}
.Nav .NavContent .NavButton .premium {
  position: absolute;
  top: 13px;
  left: 23px;
  color: #fff;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  background-color: var(--feedbacks-level-2);
  opacity: 1;
}

.Nav .NavBottom {
  display: block;
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.Nav .NavBottom a,
.Nav .NavBottom button {
  display: block;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .Nav {
    width: 105px;
    flex-direction: column;
    flex-flow: 1;
    padding: 10px 0;
    justify-content: start;
  }

  .Nav .NavContent,
  .Nav .NavContent.open {
    display: block;
    position: static;
    height: auto;
    left: auto;
    top: auto;
    width: 100%;
    text-align: center;
    background: none;
    transform: none;
  }

  .NavLogo {
    margin: 0 0 10px;
  }

  .Nav .NavOpen,
  .Nav .FilterButton {
    display: none;
  }
}
