@import '../../Colors/colors.css';

.NavButton {
  display: flex;
  line-height: 100%;
  padding: 15px 20px;
  align-items: center;
}

.active .NavButton .text, 
.NavButton:hover svg,
.NavButton:hover .text
 {
  color: var(--basic-level-7);
}

.active .NavButton .text {
  font-weight: bold;
}

.active .NavButton svg *,
.NavButton:hover svg * {
  fill: var(--basic-level-7);
}

.NavButton:focus .text{
  color: var(--basic-level-7);
}
.NavButton:focus svg{
  color: var(--basic-level-7);
}

.NavButton:hover {
  cursor: pointer;
}

.NavButton svg {
  width: 3vh;
  height: 3vh;
  display: block;
  color: var(--secondary-level-1);
}

.NavButton svg * {
  fill: var(--secondary-level-1);
}

.NavButton .text {
  font-size: 16px;
  color: var(--secondary-level-1);
  margin-left: 15px;
  line-height: 140%;
}

@media screen and (min-width: 600px) {
  .NavButton {
    display: inline-block;
    text-align: center;
    padding: 10px;
  }

  .NavButton .text {
    font-size: 13px;
    color: var(--secondary-level-1);
    margin-left: 0;
  }

  .NavButton svg {
    margin: 0 auto 10px;
  }
}
